
<template>
  <v-container fluid>
    <concluded-courses-certificate-list/>
  </v-container>
</template>

<script>


export default {
  name: 'CourseCertificateList',
  components: {
    ConcludedCoursesCertificateList: () => import('@/components/course_certificate_list/ConcludedCoursesCertificateList.vue')
  },
}
</script>

<style scoped>

</style>